"use client";

import React, { useEffect, useState } from 'react';
import {
  Footer as FooterComponent,
  StatusType,
  ThemeMode,
} from '@spheron/ui-library';
import DiscordIcon from '@spheron/ui-library/dist/assets/spheron-specific/discord-icon.svg';
import GithubIcon from '@spheron/ui-library/dist/assets/spheron-specific/git-icon.svg';
import TwitterIcon from '@spheron/ui-library/dist/assets/spheron-specific/twitter-icon.svg';
import { useTheme } from '@/utils/ThemeContext';

const Footer = () => {
  const { theme, toggleTheme } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme]);

  const footerProps = {
    copyrightText: '© 2024',
    showStatus: false,
    status: 'normal' as StatusType,
    statusText: 'All Systems Operational',
    statusUrl: 'https://status.spheron.network/',
    handleThemeClick: (theme: ThemeMode) => {
      let themeMode = theme;
      if (theme === ThemeMode.DEFAULT) {
        if (
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          themeMode = ThemeMode.DARK;
        } else {
          themeMode = ThemeMode.LIGHT;
        }
      }
      toggleTheme(theme);
      // setTheme(theme);
    },
    selectedTheme,
    iconButtons: [
      {
        id: 1,
        icon: (
          <GithubIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
        ),
        handleClick: () => window.open('https://github.com/spheronFdn/'),
      },
      {
        id: 2,
        icon: (
          <TwitterIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
        ),
        handleClick: () => window.open('https://sphn.wiki/x'),
      },
      {
        id: 3,
        icon: (
          <DiscordIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
        ),
        handleClick: () => window.open('https://sphn.wiki/discord'),
      },
    ],
    links: [
      {
        id: 1,
        text: 'Documentation',
        handleClick: () => window.open('https://docs.spheron.network/'),
      },
      // {
      //   id: 2,
      //   text: 'Join Community',
      //   handleClick: () => window.open('https://community.spheron.network/'),
      // },
      {
        id: 3,
        text: 'Changelog',
        handleClick: () => window.open('https://spheron.network/changelog/'),
      },
    ],
  };
  return (
    <FooterComponent {...footerProps} />
  );
};

export default Footer;
