import { NetworkType, networkMap, networkType } from "@/config";
import { showToastNotification, NotificationType } from "@/utils/notification";
import { BrowserProvider, Signer } from "ethers";

export async function connectMetaMaskAndGetAddress(): Promise<{
  signer: Signer;
  address: string;
} | null> {
  const network = networkMap[networkType as NetworkType];
  if (typeof window.ethereum !== "undefined") {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${network.chainId.toString(16)}` }],
      });
      const provider = new BrowserProvider(window.ethereum);

      // Get the signer
      const signer = await provider.getSigner();
      // Get the user's address
      const address = await signer.getAddress();

      console.log("MetaMask connected successfully");
      console.log("User address:", address);

      localStorage?.setItem?.("myProviderAddress", address);

      const event = new CustomEvent("myProviderAddressChanged");
      document.dispatchEvent(event);

      return { signer, address };
    } catch (error) {
      console.log("Error in Connect Wallet ->", error);
      if ((error as any).code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${network.chainId.toString(16)}`,
                chainName: network.chainName,
                rpcUrls: network.rpcUrls,
                nativeCurrency: {
                  name: network.nativeCurrency.name,
                  symbol: network.nativeCurrency.symbol,
                  decimals: network.nativeCurrency.decimals,
                },
                blockExplorerUrls: network.blockExplorerUrls,
              },
            ],
          });
          return connectMetaMaskAndGetAddress();
        } catch (addError) {
          console.error("Failed to add the network", addError);
        }
      }
      console.error("Failed to connect to MetaMask:", error);
      showToastNotification({
        title: "Error Occured",
        message: `Failed to connect to MetaMask: ${(error as any).message}`,
        type: NotificationType.Error,
        timestamp: Date.now(),
      });
    }
  } else {
    showToastNotification({
      title: "Error Occured",
      message: "MetaMask not detected",
      type: NotificationType.Error,
      timestamp: Date.now(),
    });
    console.error("MetaMask not detected");
  }
  return null;
}

export const disconnectMetaMask = () => {
  localStorage?.removeItem?.("myProviderAddress");
  const event = new CustomEvent("myProviderAddressChanged");
  document.dispatchEvent(event);
  console.log("MetaMask wallet disconnected.");
};
