"use client";

import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import NavbarComponent from "@/components/navbar";
import Footer from "@/components/footer";
import { setGlobalTheme } from "@/utils";
import OnlyClient from "@/utils/OnlyClient";
import { ThemeProvider, useTheme } from "@/utils/ThemeContext";
import PriceTicker from "@/components/price-ticker";
import { fetchGpuPrices } from "@/services/gpu-prices";

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();

  useEffect(() => {
    setGlobalTheme(theme);
  }, [theme]);

  return <>{children}</>;
};

export default function Template({ children }: { children: React.ReactNode }) {
  const [gpuPrices, setGpuPrices] = useState<
    | {
        vendor: string;
        name: string;
        averagePrice: number;
        availableNum: number;
      }[]
    | null
  >(null);

  useEffect(() => {
    let prices;
    const getPrices = async () => {
      try {
        const response = await fetchGpuPrices();
        prices = await response.json();
        setGpuPrices(prices);
      } catch (error) {
        console.error("Failed to fetch prices:", error);
      }
    };
    getPrices();
    const intervalDuration = 1 * 60 * 60 * 1000;
    const intervalId = setInterval(() => {
      getPrices();
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <ThemeProvider>
        <ThemeWrapper>
          <ToastContainer newestOnTop limit={2} />
          <nav className="sticky top-0 z-[99]">
            <NavbarComponent />
          </nav>
          <>
            <PriceTicker gpuPrices={gpuPrices} />
            <main className="lg:px-14 px-4 min-h-screen max-w-[1400px] mx-auto">
              {children}
            </main>
          </>
          <footer className="mt-[100px]">
            <OnlyClient>
              <Footer />
            </OnlyClient>
          </footer>
        </ThemeWrapper>
      </ThemeProvider>
    </>
  );
}
