import { toast } from "react-toastify";
import { Toast as NotificationToast } from '@spheron/ui-library';
import dayjs from "dayjs";

export interface INotification {
  message: any;
  timestamp: number | Date;
  type: NotificationType;
  title?: string;
}
export const enum NotificationType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
}

export const showToastNotification = (notification: INotification) => {
  let toastMethod;
  switch (notification.type) {
    case 'error':
      toastMethod = toast.error;
      break;
    case 'success':
      toastMethod = toast.success;
      break;
    case 'info':
      toastMethod = toast.info;
      break;
    case 'warning':
      toastMethod = toast.warning;
      break;
    default:
      toastMethod = toast.info;
  }
  toastMethod(
    <NotificationToast
      toastType={notification.type}
      title={notification.title || ''}
      subTitle={notification.message || ''}
      showClose={false}
      date={dayjs(notification.timestamp).format('YYYY-MM-DD')}
      time={dayjs(notification.timestamp).format('hh:mm A')}
    />,
    {
      toastId: `notif_${notification.timestamp}`,
      autoClose: 5000,
      hideProgressBar: true,
      theme: 'colored',
      icon: false,
      onClose: () => {
      },
    }
  );
};
